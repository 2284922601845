import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <br/>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-4">
          <img className="img-thumbnail rounded-circle" src="/best_profile_picture.jpg" alt="Elliot Massey profile" />
        </div>
      </div>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 text-center">
          <h1>Elliot Massey</h1>
          <h2>Software Engineer</h2>
          <h3><a href="https://twitter.com/hxtechteam">@hxtechteam</a> / <a href="https://twitter.com/hxlife">@hxlife</a></h3>
        </div>
      </div>
      <br/>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6">
          <h4>Amazing, you've found me</h4>
          <p>Now that you're on my little React app that announces me to the world. Please read on.</p>
        </div>
      </div>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Bits about me</h5>
              <p className="card-text">
                <ul>
                  <li>Hailing from Berkshire. Now residing in Kent.</li>
                  <li>Graduated University of Kent, been working in Software Engineering consistently since 2016.</li>
                  <li>Obtained a taste for real ales, craft beer, wine, whiskey and gin. (Writing that down seems worse..)</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My recent skillage</h5>
              <p className="card-text">
                <ul>
                  <li>Front-end technologies namely React, Angular (2+).</li>
                  <li>Server-side frameworks - Express.js, Restify.</li>
                  <li>Networking techniques - RPC, HATEOS, REST.</li>
                  <li>MySQL databases and document stores.</li>
                  <li>Experience with developing in Amazon, Firebase and Netlify.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Find me around</h5>
              <div className="text-center">
                <a className="card-link" href="https://twitter.com/elliotcmassey">Twitter</a>
                <a className="card-link" href="https://github.com/elliotcmassey">GitHub</a>
                <a className="card-link" href="https://www.linkedin.com/in/elliotmassey">LinkedIn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  );
}

export default App;
